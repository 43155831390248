<template>
  <b-container class="import-wizard-step02 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <b-table ref="CheckTable" class="spirecta-simple-table accounts-table" show-empty hover responsive striped
          stacked="md"
          :fields="tableFields"
          :items="updateData"
        >
          <template v-slot:cell(tink_account_name)="row">
            {{ row.value }}<br />({{ row.item.tink_account_number }})
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'CreateFromTinkWizardStep04',
  props: ['update-data'],
  data () {
    return {
      translationPath: 'assets_liabilities.create_from_tink_wizard.step4.'
    }
  },
  computed: {
    tableFields () {
      return [
        { key: 'tink_account_name', label: this.$t(this.translationPath + 'th_source') },
        { key: 'title', label: this.$t(this.translationPath + 'th_title') },
        { key: 'group_title', label: this.$t(this.translationPath + 'th_group_title') }
      ]
    }
  }
}
</script>
