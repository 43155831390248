<template>
  <b-container class="import-wizard-step02 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <template v-if="!isLoaded">
          <loader />
        </template>
        <template v-else>
          <b-row>
            <b-col cols="12" md="6"><h1>{{ $t('common.assets') }}</h1></b-col>
            <b-col class="text-right" cols="12" md="6">
              <b-link class='btn btn-xs btn-outline-secondary float-right mt-1' href='#' @click.prevent="onCreateGroupClick('asset')">
                <i class="flaticon stroke plus"></i> {{ $t( translationPath + 'new_asset_group') }}
              </b-link>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table ref="importTable" class="spirecta-simple-table accounts-table" show-empty hover responsive striped
                stacked="md"
                :fields="tableFieldsAssets"
                :items="tinkAccounts.asset"
              >
                <template v-slot:cell(number)="row">
                  {{ row.item.name }}<br />({{ row.value }})
                </template>
                <template v-slot:cell(spirectaAccountName)="row">
                  <b-form-input v-model="row.item.spirectaAccountName" @input="emitCompleted" :class="row.item.titleStyleClass" />
                  <div class="invalid-feedback">{{ row.item.titleInvalidMessage }}</div>
                </template>
                <template v-slot:cell(spirectaAccountGroup)="row">
                  <b-form-select
                    v-model="row.item.spirectaAccountGroup"
                    :options="accountGroupOptionsAsset"
                    :class="row.item.groupStyleClass"
                    @change="emitCompleted"
                  />
                </template>
                <template v-slot:cell(actions)="row">
                  <button
                    class="btn plain-btn text-regular action-button"
                    @click="onDeleteRow(row.item, 'asset')"
                  >
                    <i class="flaticon solid trash-3 text-secondary"></i>
                    {{ $t("common.delete") }}
                  </button>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="12" md="6"><h1>{{ $t('common.liabilities') }}</h1></b-col>
            <b-col class="text-right" cols="12" md="6">
              <b-link class='btn btn-xs btn-outline-secondary float-right mt-1'
                    href='#' @click.prevent="onCreateGroupClick('liability')">
                <i class="flaticon stroke plus"></i> {{ $t( translationPath + 'new_liability_group') }}
              </b-link>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table ref="importTable" class="spirecta-simple-table accounts-table" show-empty hover responsive striped
                stacked="md"
                :fields="tableFieldsLiabilities"
                :items="tinkAccounts.liability"
              >
                <template v-slot:cell(number)="row">
                  {{ row.item.name }}<br />({{ row.value }})
                </template>
                <template v-slot:cell(spirectaAccountName)="row">
                  <b-form-input v-model="row.item.spirectaAccountName" @input="emitCompleted" :class="row.item.titleStyleClass" />
                  <div class="invalid-feedback">{{ row.item.titleInvalidMessage }}</div>
                </template>
                <template v-slot:cell(spirectaAccountGroup)="row">
                  <b-form-select
                    v-model="row.item.spirectaAccountGroup"
                    :options="accountGroupOptionsLiability"
                    :class="row.item.groupStyleClass"
                    @change="emitCompleted"
                  />
                </template>
                <template v-slot:cell(actions)="row">
                  <button
                    class="btn plain-btn text-regular action-button"
                    @click="onDeleteRow(row.item, 'liability')"
                  >
                    <i class="flaticon solid trash-3 text-secondary"></i>
                    {{ $t("common.delete") }}
                  </button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>

    <create-account-group-modal
      ref="CreateAccountGroupModal"
      :type="groupTypeToCreate"
      @created="onGroupCreated"
    />
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import CreateAccountGroupModal from '@/components/modals/CreateAccountGroupModal.vue'

export default {
  name: 'CreateFromTinkWizardStep03',
  props: ['connection', 'provider'],
  components: { Loader, CreateAccountGroupModal },
  data () {
    return {
      translationPath: 'assets_liabilities.create_from_tink_wizard.step3.',
      tinkAccounts: { asset: [], liability: [] },
      accountGroups: { asset: [], liability: [] },
      spirectaAccountNames: [],
      groupTypeToCreate: 'asset',
      isLoaded: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    tableFieldsAssets () {
      return [
        { key: 'number', label: this.$t(this.translationPath + 'th_tink_asset_info') },
        { key: 'spirectaAccountName', label: this.$t(this.translationPath + 'th_spirecta_asset_name') },
        { key: 'spirectaAccountGroup', label: this.$t(this.translationPath + 'th_asset_group'), class: 'td-account-group' },
        { key: 'actions', label: '' }
      ]
    },
    tableFieldsLiabilities () {
      return [
        { key: 'number', label: this.$t(this.translationPath + 'th_tink_liability_info') },
        { key: 'spirectaAccountName', label: this.$t(this.translationPath + 'th_spirecta_liability_name') },
        { key: 'spirectaAccountGroup', label: this.$t(this.translationPath + 'th_liability_group'), class: 'td-account-group' },
        { key: 'actions', label: '' }
      ]
    },
    accountGroupOptionsAsset () {
      const opts = []
      this.accountGroups.asset.map(g => {
        opts.push({
          text: g.title,
          value: g
        })
      })

      return opts
    },
    accountGroupOptionsLiability () {
      const opts = []
      this.accountGroups.liability.map(g => {
        opts.push({
          text: g.title,
          value: g
        })
      })

      return opts
    }
  },
  methods: {
    init () {
      Promise.all([
        this.loadTinkAccounts(),
        this.loadAccountGroups(),
        this.loadSpirectaAccounts()
      ])
        .then(() => {
          this.isLoaded = true
          this.validate()
        })
    },
    async loadTinkAccounts () {
      return new Promise((resolve, reject) => {
        this.tinkAccounts = { asset: [], liability: [] }
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/accounts/${this.connection.id}`)
          .then(response => {
            const tinkAccounts = response.data.data.db_accounts.sort((a, b) => a.name > b.name ? 1 : -1)
            tinkAccounts.map(a => {
              a.spirectaAccountName = a.name + ' (' + a.number.substr(-5) + ')'
              a.isImportable = ['checking', 'credit_card'].indexOf(a.type.toLowerCase()) !== -1 ? 1 : 0
            })

            this.tinkAccounts.asset = tinkAccounts.filter(a => ['savings', 'checking', 'investment', 'pension', 'other', 'external'].indexOf(a.type.toLowerCase()) !== -1)
            this.tinkAccounts.liability = tinkAccounts.filter(a => ['loan', 'credit_card'].indexOf(a.type.toLowerCase()) !== -1)
            resolve(this.tinkAccounts)
          })
          .catch(err => {
            console.error(err)
            reject(this.err)
          })
      })
    },
    async loadSpirectaAccounts () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=income,expense,asset,liability&limit=100`)
          .then(response => {
            response.data.data.map(a => {
              this.spirectaAccountNames.push(a.title)
            })
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    async loadAccountGroups () {
      return new Promise((resolve, reject) => {
        this.accountGroups = { asset: [], liability: [] }
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=asset,liability&limit=1000`)
          .then(response => {
            const allGroups = response.data.data.sort((a, b) => a.title > b.title ? 1 : -1)
            this.accountGroups.asset = allGroups.filter(g => g.type === 'asset')
            this.accountGroups.liability = allGroups.filter(g => g.type === 'liability')
            resolve(this.accountGroups)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    validate () {
      let isValid = true
      const tinkAccounts = this.tinkAccounts.asset.concat(this.tinkAccounts.liability)

      const names = []
      tinkAccounts.map(a => {
        a.titleStyleClass = ''
        a.titleInvalidMessage = ''
      })
      for (const i in tinkAccounts) {
        if (!tinkAccounts[i].spirectaAccountName) {
          isValid = false
          tinkAccounts[i].titleStyleClass = 'is-invalid'
          tinkAccounts[i].titleInvalidMessage = this.$t(this.translationPath + 'errors.title_required')
        } else if (tinkAccounts[i].spirectaAccountName.length < 2) {
          isValid = false
          tinkAccounts[i].titleStyleClass = 'is-invalid'
          tinkAccounts[i].titleInvalidMessage = this.$t(this.translationPath + 'errors.title_minlength')
        } else if (tinkAccounts[i].spirectaAccountName.length > 255) {
          isValid = false
          tinkAccounts[i].titleStyleClass = 'is-invalid'
          tinkAccounts[i].titleInvalidMessage = this.$t(this.translationPath + 'errors.title_maxlength')
        }

        if (!tinkAccounts[i].spirectaAccountGroup) {
          isValid = false
        }

        // Check for duplicated spirecta account names.
        if (names.indexOf(tinkAccounts[i].spirectaAccountName) !== -1 || this.spirectaAccountNames.indexOf(tinkAccounts[i].spirectaAccountName) !== -1) {
          isValid = false
          tinkAccounts[i].titleStyleClass = 'is-invalid'
          tinkAccounts[i].titleInvalidMessage = this.$t(this.translationPath + 'errors.title_not_unique')
        }
        names.push(tinkAccounts[i].spirectaAccountName)
      }

      if (isValid) {
        this.$emit('validate-success')
      } else {
        this.$emit('validate-fail')
      }

      return isValid
    },
    emitCompleted () {
      if (!this.validate()) {
        return false
      }

      const tinkAccounts = this.tinkAccounts.asset.concat(this.tinkAccounts.liability)
      const updateData = []
      tinkAccounts.map(a => {
        updateData.push({
          title: a.spirectaAccountName ? a.spirectaAccountName : null,
          group_id: a.spirectaAccountGroup ? a.spirectaAccountGroup.id : null,
          group_title: a.spirectaAccountGroup.title,
          tink_account_number: a.number,
          tink_account_name: a.name,
          type: a.spirectaAccountGroup.type,
          is_importable: a.isImportable
        })
      })

      this.$emit('completed', { updateData: updateData })
    },
    onCreateGroupClick (groupType) {
      this.groupTypeToCreate = groupType
      this.$refs.CreateAccountGroupModal.show()
    },
    onGroupCreated (newGroup) {
      this.loadAccountGroups()
      this.$bvToast.toast(this.$t(this.translationPath + 'toast.' + (this.currentUser.strict_accounting_mode ? 'account' : 'category') + '_group_create_success', { title: newGroup.title }), {
        title: this.$t(this.translationPath + 'toast.' + (this.currentUser.strict_accounting_mode ? 'account' : 'category') + '_group_create_title'),
        variant: 'success',
        solid: true,
        'auto-hide-delay': 3000
      })
      this.$refs.CreateAccountGroupModal.hide()
    },
    onDeleteRow (item, type) {
      this.tinkAccounts[type] = this.tinkAccounts[type].filter(a => a.id !== item.id)
      this.validate()
    }
  }
}
</script>
