<template>
  <b-container class="import-wizard-step01 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <template v-if="isLoaded">
          <b-tabs ref="SelectConnectionTabs" @input="onTabShown">
            <b-tab v-if="connections.length" :title="$t(translationPath + 'tab_existed')">
              <b-table ref="importTable" class="spirecta-simple-table accounts-table spirecta-table-where-row-is-selected" show-empty hover responsive striped
                 :items="connections"
                 :fields="tableFields"
                 :busy="!isLoaded"
                 selectable
                 select-mode="single"
                 @row-selected="connectionSelected"
                 stacked="md"
                 :emptyText="$t(translationPath + 'no_data')"
              >
                <template v-slot:cell(provider)="row">
                  {{ $t(row.item.tink_provider.translation_string_id) }}
                </template>
              </b-table>
            </b-tab>
            <b-tab :title="$t(translationPath + 'tab_create')">
              <create-tink-connection ref="CreateConnection" :show-save-button="false" />
            </b-tab>
          </b-tabs>
        </template>
        <template v-else>
          <loader />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import CreateTinkConnection from '@/components/common/CreateTinkConnection.vue'

export default {
  name: 'CreateFromTinkWizardStep01',
  components: { Loader, CreateTinkConnection },
  data () {
    return {
      translationPath: 'assets_liabilities.create_from_tink_wizard.step1.',
      connections: [],
      connection: null,
      useTestProviders: process.env.VUE_APP_USE_TINK_TEST_PROVIDERS === '1',
      tabIndex: 0,
      tab: '',
      isConnectionCreated: false,
      isLoaded: false
    }
  },
  computed: {
    tableFields () {
      return [
        { key: 'provider', label: this.$t('common.bank') },
        { key: 'name', label: this.$t('common.title') }
      ]
    }
  },
  created () {
    Promise.all([
      this.loadConnections()
    ])
      .then(() => {
        this.isLoaded = true
      })
  },
  methods: {
    async loadConnections () {
      this.connections = []

      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/connections`)
          .then(response => {
            this.connections = response.data.data
            this.connections.map(i => {
              const tmp = i.name.match(/#.+#/)
              if (tmp !== null) {
                i.name = i.name.replace(/#.+#/, this.$t(tmp[0].replaceAll('#', '')))
              }
            })
            resolve(this.connections)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    connectionSelected (rows) {
      if (rows.length) {
        this.connection = rows[0]
        this.$emit('completed', { connection: this.connection })
        this.$emit('validate-success')
      } else {
        this.$emit('validate-fail')
      }
    },
    onTabShown (tabIndex) {
      this.tabIndex = tabIndex
      if (tabIndex === 0 && this.connections.length) {
        this.tab = 'select-connection'
        this.$emit('completed', { connection: this.connection })
        if (this.connection) {
          this.$emit('validate-success')
        } else {
          this.$emit('validate-fail')
        }
      } else if (tabIndex === 0 && !this.connections.length) {
        this.$emit('validate-success')
        this.tab = 'create-connection'
      } else if (tabIndex === 1) {
        this.$emit('validate-success')
        this.tab = 'create-connection'
      }
    },
    validate () {
      if (!this.connections.length) {
        return this.isConnectionCreated
      } else if (this.connections.length && this.tabIndex === 0) {
        return Boolean(this.connection)
      } else if (this.connections.length && this.tabIndex === 1) {
        return this.isConnectionCreated
      } else {
        return false
      }
    },
    async $storeConnection () {
      return new Promise((resolve, reject) => {
        this.$refs.CreateConnection.onSave()
          .then(r => {
            this.isConnectionCreated = r.response.status === 201
            this.$emit('completed', { connection: r.response.data.data, provider: r.provider })
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/spirecta-table-where-row-is-selected.scss';
</style>
