<template>
  <b-modal
    id="create-account-group-modal"
    centered
    ref="createModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
        {{ $t(translationPath + 'title_'+type.toLowerCase()) }}
    </template>
    <template v-if="isLoaderShown">
      <loader :loading-text="''" />
    </template>
    <template v-else>
      <b-form-group
        label-class="pr-4 text-black"
        label-for="title"
        label-cols-lg="3"
        label-align-lg="right"
        required
        class="pt-md-4"
        :invalid-feedback="invalidTitleFeedback"
        >

        <template slot="label">{{ $t(translationPath + 'labels.title') }}</template>
        <b-form-input id="title" class="rounded-pill"
                      :state="$v.title.$dirty ? !$v.title.$error : null"
                      v-model="title"
                      @input="isTitleUnique = true"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label-class="'pr-4 text-black pt-1'"
        label-cols-lg="4"
        label-align-lg="right"
        :label="$t(translationPath + 'labels.toggle_advanced')"
      >
        <b-form-checkbox v-model="isAdvancedOptionsShown" name="toggle-button" class='' size="lg" switch>
        </b-form-checkbox>
      </b-form-group>

      <template v-if="isAdvancedOptionsShown">
        <b-form-group
          label-class="pr-4 text-black"
          label-for="code"
          label-cols-lg="3"
          label-align-lg="right"
          required
          class="pt-md-4"
          :invalid-feedback="invalidCodeFeedback"
          >

          <template slot="label">{{ $t(translationPath + 'labels.code') }}</template>
          <b-form-input id="code" class="rounded-pill"
                        :state="$v.code.$dirty ? !$v.code.$error : null"
                        v-model="code"
                        @input="isCodeUnique = true"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-class="pr-4 text-black"
          label-for="description"
          label-cols-lg="3"
          label-align-lg="right"
        >
          <template slot="label">{{ $t(translationPath + 'labels.description') }}</template>
          <b-form-textarea id="description" v-model="description" rows="8"></b-form-textarea>
        </b-form-group>
      </template>
    </template>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancel">{{ $t('common.cancel') }}</b-button>
      <b-button variant="primary"  @click="onCreate">{{ $t(translationPath + 'btn_create') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

const isTitleUnique = getter => function () {
  return this.isTitleUnique
}
const isCodeUnique = getter => function () {
  return this.isCodeUnique
}

export default {
  name: 'CreateAccountGroupModal',
  components: { Loader },
  props: {
    type: {
      type: String,
      default: 'income'
    }
  },
  data () {
    return {
      translationPath: 'modals.create_account_group.',
      title: null,
      code: null,
      description: null,
      isTitleUnique: true,
      isCodeUnique: true,
      isAdvancedOptionsShown: false,
      isLoaderShown: false
    }
  },
  validations: {
    title: { required, minLength: minLength(2), maxLength: maxLength(255), titleUnique: isTitleUnique() },
    code: { required, codeUnique: isCodeUnique() },
    form: ['title', 'code']
  },
  computed: {
    ...mapState('user', ['currentUser']),
    invalidTitleFeedback () {
      if (this.$v.title.required === false) { return this.$i18n.t(this.translationPath + 'errors.title_required') }
      if (this.$v.title.minLength === false) { return this.$i18n.t(this.translationPath + 'errors.title_minlength') }
      if (this.$v.title.maxLength === false) { return this.$i18n.t(this.translationPath + 'errors.title_maxlength') }
      if (this.$v.title.titleUnique === false) { return this.$i18n.t(this.translationPath + 'errors.title_already_taken') }
      return ''
    },
    invalidCodeFeedback () {
      if (this.$v.code.required === false) { return this.$i18n.t(this.translationPath + 'errors.code_required') }
      if (this.$v.code.codeUnique === false) { return this.$i18n.t(this.translationPath + 'errors.code_already_taken') }
      return ''
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
      this.reset()
    },
    show () {
      this.$refs.createModal.show()
      this.$emit('show')
      process.nextTick(() => {
        this.suggestCode()
      })
    },
    hide () {
      this.$refs.createModal.hide()
      this.$emit('hide')
    },
    onCancel () {
      this.$refs.createModal.hide()
      this.$emit('cancelled')
    },
    reset () {
      this.title = null
      this.code = null
      this.description = null
      this.isLoaderShown = false
      this.isAdvancedOptionsShown = false
      this.isTitleUnique = true
      this.isCodeUnique = true
      this.$v.form.$reset()
    },
    async onCreate () {
      const isValid = await this.validate()
      if (!isValid) {
        return false
      }

      this.isLoaderShown = true
      const postData = {
        type: this.type,
        title: this.title,
        code: this.code,
        description: this.description
      }

      axios.post(process.env.VUE_APP_ROOT_API + '/accounts/groups', postData)
        .then(response => {
          this.$emit('created', response.data.data)
        })
        .catch(err => {
          console.error(err)
          this.$emit('error', err)
        })
    },
    suggestCode () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/accountant/suggest-account-group-code/${this.type}`)
        .then((response) => {
          this.code = response.data.data.suggested_code
        })
        .catch((error) => {
          console.error(error)
          this.isLoaderShown = false
        })
    },
    async validate () {
      return new Promise((resolve, reject) => {
        this.$v.form.$touch()
        if (this.$v.form.$invalid) {
          resolve(false)
        } else {
          axios.get(process.env.VUE_APP_ROOT_API + '/accounts/groups/for/multiselect?&log=CreateAccountGroupModal')
            .then(response => {
              const titles = []
              const codes = []
              response.data.data.map(g => {
                titles.push(g.title)
                codes.push(g.code)
              })
              if (titles.indexOf(this.title) !== -1) {
                this.isTitleUnique = false
              }
              if (codes.indexOf(Number(this.code)) !== -1) {
                this.isCodeUnique = false
              }
              if (this.$v.form.$invalid) {
                resolve(false)
              } else {
                resolve(true)
              }
            })
            .catch(err => {
              reject(err)
            })
        }
      })
    }
  }
}
</script>
