<template>
  <b-container class="import-wizard-step02 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <template v-if="isLoaderShown">
          <loader :loading-text="$t(translationPath + 'loading_text')" />
        </template>
        <template v-else>
          <div v-if="isAuthIframeShown" class="tink-iframe-wrap">
            <b-embed id="bank_iframe" ref="TinkIframe" type="iframe" :src="tinkLink" class="mt-5 tink-iframe" allow-scripts allow-same-origin allow-popups allow-forms allow-popups-to-escape-sandbox allow-top-navigation></b-embed>
          </div>
          <div v-else>
            <b-alert :show="isConnected" variant="success">{{ $t(translationPath + 'toast_success', { name: $t(usedProvider.translation_string_id) }) }}</b-alert>
            <b-alert :show="!isConnected" variant="danger">{{ $t(translationPath + 'toast_general_error') }}</b-alert>
          </div>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import Tink from '@/mixins/Tink.js'

export default {
  name: 'CreateFromTinkWizardStep02',
  components: { Loader },
  mixins: [Tink],
  props: ['provider', 'connection'],
  data () {
    return {
      translationPath: 'assets_liabilities.create_from_tink_wizard.step2.',
      isLoaderShown: true,
      tempConnection: null,
      isAuthIframeShown: false,
      isConnected: false,
      credentialsAuthCode: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    locale () {
      let returnvalue = 'en_US'
      switch (this.currentUser.default_lang) {
        case 'sv':
          returnvalue = 'sv_SE'
          break
        case 'da':
          returnvalue = 'da_DK'
          break
        case 'en':
        default:
          returnvalue = 'en_US'
      }

      return returnvalue
    },
    tinkLink () {
      let link = `https://link.tink.com/1.0/credentials/add?client_id=${this.currentUser.tink_client_id}&scope=accounts:read,investments:read,transactions:read,user:read,credentials:read,identity:read&market=SE&locale=${this.locale}&iframe=true&authorization_code=${this.credentialsAuthCode}&redirect_uri=${document.location.origin}/tink-callback`

      link += '&input_provider=' + this.usedProvider.provider_name

      return link
    },
    usedProvider () {
      if (this.provider) {
        return this.provider
      } else {
        return this.connection.tink_provider
      }
    }
  },
  methods: {
    async init () {
      this.reinitializeConnection(this.connection.id).then(clearedConnection => {
        this.credentialsAuthCode = clearedConnection.tink_credentials_authorization_code
        this.isAuthIframeShown = true
        this.isLoaderShown = false
      })
    },
    async getClearedConnection () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/cleared-connection/${this.connection.id}`)
          .then(response => {
            this.credentialsAuthCode = response.data.data.tink_credentials_authorization_code
            resolve(this.credentialsAuthorizationCode)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    receiveIframeMessage (event) {
      if (event.origin !== 'https://link.tink.com') {
        return false
      }

      const { type, error, additionalInformation } = JSON.parse(event.data)
      if (type !== 'error' && type !== 'none') {
        return false
      }

      this.isIframeShown = false
      if (error) {
        this.writeLog(
          'FAILED',
          'Import accounts from tink wizard: ' + error.message,
          this.tinkLink,
          this.usedProvider.provider_name,
          this.usedProvider.id,
          this.connection.id
        )
        this.$bvToast.toast(this.$t(this.translationPath + 'toast_error', { message: error.message }), {
          title: this.$t(this.translationPath + 'toast_title'),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
      }

      const credentialsId = Object.prototype.hasOwnProperty.call(additionalInformation, 'credentialsId') ? additionalInformation.credentialsId : null
      if (type === 'none' && credentialsId) {
        this.isLoaderShown = true
        this.isAuthIframeShown = false
        this.writeLog(
          'OK',
          'Import accounts from tink wizard: ' + 'Receive credentials id: ' + credentialsId,
          this.tinkLink,
          this.usedProvider.provider_name,
          this.usedProvider.id,
          this.connection.id
        )
        this.saveCredentials(credentialsId, this.connection.id)
          .then(response => {
            this.writeLog(
              'OK',
              'Import accounts from tink wizard: ' + 'Credentials ' + credentialsId + ' are saved to database.',
              this.tinkLink,
              this.usedProvider.provider_name,
              this.usedProvider.id,
              this.connection.id
            )
            this.isLoaderShown = false
            this.isConnected = true
            this.$emit('completed', { connection: this.connection, provider: this.usedProvider })
            this.$emit('validate-success')
            this.$bvToast.toast(this.$t(this.translationPath + 'toast_success', { name: this.$t(this.usedProvider.translation_string_id) }), {
              title: this.$t(this.translationPath + 'toast_title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
          .catch(err => {
            console.error(err)
            this.writeLog(
              'FAILED',
              'Import accounts from tink wizard: ' + 'Credentials ' + credentialsId + ' are not saved to database.',
              this.tinkLink,
              this.usedProvider.provider_name,
              this.usedProvider.id,
              this.connection.id
            )
            this.$bvToast.toast(this.$t(this.translationPath + 'toast_general_error'), {
              title: this.$t(this.translationPath + 'toast_title'),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
        return true
      }

      return false
    },
    validate () {
      return Boolean(this.isConnected)
    }
  }
}
</script>

<style lang=scss>
.import-wizard-step02 {
  .tink-iframe-wrap {
    width: 420px;
    margin: 0 auto;
    .embed-responsive {
      height: 600px;
      iframe {
        height: 92%;
      }
    }
  }
}
</style>
