<template>
  <b-container class="create-from-tink-wizard bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col>
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">{{ $t(translationPath + 'title') }}</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="step-container-v2">
            <div class="stepper bg-light mx-auto text-regular">
              <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="CreateStep" @on-change="onTabChange">
                <tab-content :title="$t(translationPath + 'step_labels.step1')" :before-change="() => validateStep(1)">
                  <create-from-tink-wizard-step-01
                    ref="CreateStep1"
                    @completed="mergePartialModels"
                    @validate-success="setDisabledStatus(false, 0)"
                    @validate-fail="setDisabledStatus(true, 0)"
                  ></create-from-tink-wizard-step-01>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step2')" :before-change="() => validateStep(2)">
                  <create-from-tink-wizard-step-02
                    ref="CreateStep2"
                    :connection="Object.prototype.hasOwnProperty.call(finalModel, 'connection') ? finalModel.connection : null"
                    :provider="Object.prototype.hasOwnProperty.call(finalModel, 'provider') ? finalModel.provider : null"
                    @completed="mergePartialModels"
                    @validate-success="setDisabledStatus(false, 1)"
                    @validate-fail="setDisabledStatus(true, 1)"
                  ></create-from-tink-wizard-step-02>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step3')" :before-change="() => validateStep(3)">
                  <create-from-tink-wizard-step-03
                    ref="CreateStep3"
                    :connection="Object.prototype.hasOwnProperty.call(finalModel, 'connection') ? finalModel.connection : null"
                    :provider="Object.prototype.hasOwnProperty.call(finalModel, 'provider') ? finalModel.provider : null"
                    @completed="mergePartialModels"
                    @validate-success="setDisabledStatus(false, 2)"
                    @validate-fail="setDisabledStatus(true, 2)"
                  ></create-from-tink-wizard-step-03>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step4')" :before-change="() => validateStep(4)">
                  <create-from-tink-wizard-step-04
                    ref="CreateStep4"
                    :update-data="Object.prototype.hasOwnProperty.call(finalModel, 'updateData') ? finalModel.updateData : null"
                  ></create-from-tink-wizard-step-04>
                </tab-content>
                <template slot="footer" slot-scope="props">
                  <div :class="{ hasSpace: props.activeTabIndex != 0 }">
                    <div class="wizard-footer-left">
                      <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
                        {{ $t('common.previous') }}
                      </wizard-button>
                    </div>
                    <div class="line" v-if="props.activeTabIndex != 0"></div>
                    <div class="wizard-footer-right">
                      <wizard-button tabindex="6" v-if="!props.isLastStep" @click.native="onNextClick(props)" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">
                        {{ $t('common.next') }}
                      </wizard-button>
                      <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" tabindex="5" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">{{$t('common.finalize_the_import')}}</wizard-button>
                    </div>
                  </div>
                </template>
              </form-wizard>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <success-modal
      :title="$t(translationPath+'success_modal.title')"
      :message="$t(translationPath+'success_modal.message')"
      :button="$t(translationPath+'success_modal.button_text')"
      :redirect="'/reports/other/account-plan-v2/assets'"
      :orLinkText="$t(translationPath+'success_modal.or_link_text')"
      :orLink="'/redirect?to=importAccountsTink'"
      @hide="onHideSuccessModal"
    ></success-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import CreateFromTinkWizardStep01 from './CreateFromTinkWizardStep01.vue'
import CreateFromTinkWizardStep02 from './CreateFromTinkWizardStep02.vue'
import CreateFromTinkWizardStep03 from './CreateFromTinkWizardStep03.vue'
import CreateFromTinkWizardStep04 from './CreateFromTinkWizardStep04.vue'
import SuccessModal from '@/components/modals/SuccessModal'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'CreateFromTinkWizard',
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    CreateFromTinkWizardStep01,
    CreateFromTinkWizardStep02,
    CreateFromTinkWizardStep03,
    CreateFromTinkWizardStep04,
    SuccessModal
  },
  data () {
    return {
      translationPath: 'assets_liabilities.create_from_tink_wizard.',
      nextDisabled: {
        0: true,
        1: true,
        2: true,
        3: false
      },
      finalModel: {}
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.manage_title'), to: '/reports/other/account-plan-v2/assets' },
        { text: this.$t(this.translationPath + 'title_assets_liabilities') },
        { text: '', to: '', active: true }
      ]
    }
  },
  methods: {
    onNextClick (props) {
      if (props.activeTabIndex === 0 && this.$refs.CreateStep1.tab === 'create-connection') {
        this.$refs.CreateStep1.$storeConnection()
          .then(() => {
            props.nextTab()
          })
      } else {
        props.nextTab()
      }
    },
    validateStep (step) {
      const reference = this.$refs['CreateStep' + step]
      const isValid = reference.validate()
      return isValid
    },
    setDisabledStatus (value, stepIndex) {
      this.nextDisabled[stepIndex] = value
    },
    mergePartialModels (model) {
      this.finalModel = Object.assign({}, this.finalModel, model)
    },
    onTabChange (oldIndex, newIndex) {
      if (oldIndex === 0 && newIndex === 1) {
        this.$refs.CreateStep2.init()
      } else if (oldIndex === 1 && newIndex === 2) {
        this.$refs.CreateStep3.init()
      }

      if (this.$refs.CreateStep2) {
        this.$refs.CreateStep2.$removeEvents()
        if (newIndex === 1) {
          this.$refs.CreateStep2.$listenEvents()
        }
      }
    },
    onComplete () {
      const postData = { accounts: [] }
      this.finalModel.updateData.map(accountData => {
        postData.accounts.push({
          type: accountData.type,
          group_id: accountData.group_id,
          title: accountData.title,
          is_importable: accountData.is_importable
        })
      })

      axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/store-multiple`, postData)
        .then(response => {
          this.$bvModal.show('successModal')
        })
        .catch(err => {
          console.error(err)
        })
    },
    onHideSuccessModal () {
      this.$router.push('/redirect?to=importAccountsTink')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
.create-from-tink-wizard {
  .wizard-tab-content{
    padding-left:0;
    padding-right:0;
  }
  .import-wizard-step {
    .accounts_wrapper {
      border-left: 1px solid #D7D7D7;
      @media screen and (max-width:991.98px) {
        border-left: none;
      }
    }
  }
  h1 {
    color: #545b7a;
  }
  h2 {
    color: #545b7a;
  }
  h4 {
    color: #545b7a;
  }
  .FormLabels {
    color: #545b7a;
    font-weight: 600;
  }
  legend {
    color: #545b7a;
    font-weight: 600;
  }
  label {
    color: #545b7a;
    font-weight: 600;
  }
  .td-title {
    min-width: 200px;
  }
  a[role="tab"] {
    font-size: 16px;
  }
}
</style>
